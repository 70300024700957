.null-border.react-datepicker-component .react-datepicker-input input {
  font-size: 17px;
  width: 100%;
  border-radius: 5px;
  height: 40px;
  background-color: #f0f5f9;

  font-weight: 200;
  outline: transparent !important;
}

.null-border.react-datepicker-component .react-datepicker-input {
  border: transparent !important;
  outline: transparent !important;
}

.nulll-border.react-datepicker-component .react-datepicker-input input {
  font-size: 17px;
  width: 100%;
  border-radius: 5px;
  height: 40px;
  background-color: #f0f5f9;

  font-weight: 200;
  outline: transparent !important;
}

.nulll-border.react-datepicker-component .react-datepicker-input {
  border: transparent !important;
  outline: transparent !important;
}

.nulll-border.react-datepicker-component {
  width: 100% !important;
}

.light-border.react-datepicker-component .react-datepicker-input input {
  font-size: 17px;
  width: 100%;
  border-radius: 5px;
  height: 40px;
  /* background-color: white; */
  font-weight: 200;
  outline: transparent !important;
}

.light-border.react-datepicker-component .react-datepicker-input {
  height: 40px;
}

.pink-border.react-datepicker-component .react-datepicker-input input {
  border-radius: 5px;
}

.pink-border.react-datepicker-component .react-datepicker-input {
  border-radius: 5px !important;
  border-color: #fc0fc0 !important;
  height: 39px !important;
  background-color: white !important;
}

.pink-border.react-datepicker-component .react-datepicker-input input:focus {
  border-color: #fc0fc0 !important;
  box-shadow: 0 0 0 1px #fc0fc0 !important;
}

.pink-border.react-datepicker-component .react-datepicker-input:hover {
  border-color: #fc0fc0 !important;
  box-shadow: 0 0 0 1px #fc0fc0 !important;
  border-radius: 5px !important;
  background-color: white !important;
}

.pink-border.react-datepicker-component .react-datepicker-input input:hover {
  box-shadow: 0 0 0 1px #fc0fc0 !important;
  border-radius: 5px !important;
  background-color: white !important;
}

.red-border.react-datepicker-component .react-datepicker-input input {
  border-radius: 5px;
}

.red-border.react-datepicker-component .react-datepicker-input {
  border-radius: 5px !important;
  border-color: #ff0000 !important;
  height: 39px !important;
  background-color: white !important;
}

.red-border.react-datepicker-component .react-datepicker-input input:focus {
  border-color: #ff0000 !important;
  box-shadow: 0 0 0 1px #ff0000 !important;
}

.red-border.react-datepicker-component .react-datepicker-input:hover {
  border-color: #ff0000 !important;
  box-shadow: 0 0 0 1px #ff0000 !important;
  border-radius: 5px !important;
  background-color: white !important;
}

.red-border.react-datepicker-component .react-datepicker-input input:hover {
  box-shadow: 0 0 0 1px #ff0000 !important;
  border-radius: 5px !important;
  background-color: white !important;
}

.blue-border.react-datepicker-component .react-datepicker-input input {
  border-radius: 5px;
}

.blue-border.react-datepicker-component .react-datepicker-input {
  border-radius: 5px !important;
  border-color: #00b0f0 !important;
  height: 39px !important;
  background-color: white !important;
}

.blue-border.react-datepicker-component .react-datepicker-input input:focus {
  border-color: #00b0f0 !important;
  box-shadow: 0 0 0 1px #00b0f0 !important;
}

.blue-border.react-datepicker-component .react-datepicker-input:hover {
  border-color: #00b0f0 !important;
  box-shadow: 0 0 0 1px #00b0f0 !important;
  border-radius: 5px !important;
  background-color: white !important;
}

.blue-border.react-datepicker-component .react-datepicker-input input:hover {
  box-shadow: 0 0 0 1px #00b0f0 !important;
  border-radius: 5px !important;
  background-color: white !important;
}

.gray-border.react-datepicker-component .react-datepicker-input input {
  border-radius: 5px;
}

.gray-border.react-datepicker-component .react-datepicker-input {
  border-radius: 5px !important;
  border-color: #7f7f7f !important;
  height: 39px !important;
  background-color: white !important;
}

.gray-border.react-datepicker-component .react-datepicker-input input:focus {
  border-color: #7f7f7f !important;
  box-shadow: 0 0 0 1px #7f7f7f !important;
}

.gray-border.react-datepicker-component .react-datepicker-input:hover {
  border-color: #7f7f7f !important;
  box-shadow: 0 0 0 1px #7f7f7f !important;
  border-radius: 5px !important;
  background-color: white !important;
}

.gray-border.react-datepicker-component .react-datepicker-input input:hover {
  box-shadow: 0 0 0 1px #7f7f7f !important;
  border-radius: 5px !important;
  background-color: white !important;
}

.googlelocation {
  /* height: 40px; */
  width: 100%;
  background-color: #f0f5f9;
  border: transparent !important;
  outline: transparent !important;
  /* padding: 18px; */
  color: black;
  padding: 7px 16px;
}

.googlelocationcity {
  width: 100%;

  border: 1px solid #7f7f7f;

  color: black;
  border-radius: 5px;
}

.readonly {
  pointer-events: none;
  /* Disable pointer events */
  background-color: #f5f5f5;
  /* Optionally change the background color to indicate readonly */
}

.white-border.react-datepicker-component .react-datepicker-input input {
  background-color: #114684;
  color: white;
  height: 39px !important;
  border-radius: 5px;
  /* opacity: 0.1; */
  margin-top: -5px;
}

.white-border {
  border-radius: 5px;
}

.white-border.react-datepicker-component .react-datepicker-input input {
  background-color: rgb(17, 70, 132);
  color: white;
  height: 39px !important;
  border-radius: 5px;
  /* opacity: 0.1; */
  margin-top: -3px;
  /* border: 1px solid #ff0000 !important; */
}

/*disable datepicker style*/
.react-datepicker-component.is-disabled {
  opacity: 1 !important;
}

.react-datepicker-component.bg-blue.is-disabled {
  opacity: 1;
}

.react-datepicker-component.bg-blue .react-datepicker-input input,
.react-datepicker-component.bg-blue .react-datepicker-input.has-value input {
  background: #114684 !important;
  color: white;
}

.react-datepicker-input input {
  font-size: 15px !important;
}

.global_history .mantine-Modal-title {
  font-weight: bold !important;
  font-size: 18px !important;
}

.global_history .mantine-Modal-header {
  border-bottom-width: 1px !important;
}

.global_history .mantine-Modal-content {
  overflow-y: hidden !important;
  max-height: 100vh !important;
  display: flex !important;
  flex-direction: column !important;
}

.global_history .mantine-Modal-body {
  overflow: auto !important;
  flex-grow: 1 !important;
  padding: 16px !important;
}

/*fiscalYear End Date datepicker css */
.fiscalYearEndDate.react-datepicker-component .react-datepicker-input input {
  border-radius: 5px;
  background: #f0f5f9 !important;
  border: none !important;

  color: gray !important;
  font-size: 13px !important;
  font-weight: normal !important;
}

.fiscalYearEndDate.react-datepicker-component .react-datepicker-input {
  border-radius: 5px !important;
  border: none !important;
  /* height: 39px !important; */
}

.floatingLabel.react-datepicker-component .react-datepicker-input {
  border-radius: 5px !important;
  border-color: gray !important;
  /* height: 39px !important; */
  background: #f0f5f9 !important;
}

/* .floatingLabel.react-datepicker-component .react-datepicker-input input:focus {
  border-color: #3182ce !important;
  box-shadow: 0 0 0 1px #3182ce !important;
} */

.floatingLabel.react-datepicker-component .react-datepicker-input:hover {
  border-color: gray !important;
  /* box-shadow: 0 0 0 1px gray !important; */
}

/* fiscalEnd date mantine date picker */
.floatingLabel .mantine-DatePickerInput-input {
  border-color: gray !important;
}

.datePicker_fiscalendDate .mantine-DatePickerInput-input {
  background: #f0f5f9 !important;
}

.cursor-not-allowed {
  cursor: not-allowed;
}

/* accounting system fiscal year end date datepicker css*/
.accounting-fiscal-year-end-date.react-datepicker-component
  .react-datepicker-input {
  background-color: rgba(17, 70, 132, 0.1) !important;
  background: rgba(17, 70, 132, 0.1) !important;
}

.accounting-fiscal-year-end-date.react-datepicker-component
  .react-datepicker-input:hover {
  background-color: rgba(17, 70, 132, 0.1) !important;
}

.accounting-fiscal-year-end-date.react-datepicker-component
  .react-datepicker-input
  input:hover {
  background-color: rgba(17, 70, 132, 0.1) !important;
}
